import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { useLogin } from "../../hooks/useLogin";
import { useQuery } from "../../hooks/useQuery";
import "../evaluation/GradientButton.css";
import { useTranslation } from "react-i18next";

export default function Login() {
  const { t } = useTranslation();
  const query = useQuery();
  const [email, setEmail] = useState(query.get("email") || "");
  const [password, setPassword] = useState("");
  const { login, error, isPending } = useLogin();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMsg("");
    setIsSubmitting(true);
    login(email, password);
    setIsSubmitting(false);
  };

  return (
    <div className="flex justify-center items-center h-full">
      <div>
        <img
          className="mx-auto w-64 mb-6"
          src={
            "https://firebasestorage.googleapis.com/v0/b/avaliadordecorpos.appspot.com/o/logo_final%20(1).png?alt=media&token=bb34c3ce-0441-4dd6-883e-c078c4daddff"
          }
          alt="logo"
        />
        <form
          className="mt-5 w-full sm:w-96 rounded-sm p-5 text-sm leading-4"
          onSubmit={handleSubmit}
        >
          <input
            className="opacity-50 cursor-default text-[16px] bg-[#212129]/50 p-4 border-b-2 border-[#555] rounded-md w-full text-slate-200"
            required
            type="email"
            placeholder={t("typeEmail")}
            autoComplete="username"
            onChange={(e) => setEmail(e.target.value.toLowerCase())}
            value={email}
          />
          <div className="mt-5"></div>
          <input
            className="opacity-50 cursor-default text-[16px] bg-[#212129]/50 p-4 border-b-2 border-[#555] rounded-md w-full text-slate-200"
            required
            type="password"
            placeholder={t("typePassword")}
            autoComplete="new-password"
            onChange={(e) => setPassword(e.target.value)}
            value={password}
          />
          <div style={{ textAlign: "right" }}>
            <div
              role="button"
              tabIndex={0}
              className="text-yellow-400/50 mt-5 cursor-pointer"
              onClick={() => navigate("/recover")}
            >
              {t("forgotPassword")}
            </div>
          </div>
          <button
            className={`mt-6 w-full button-gradient text-amber-900 font-semibold py-4 px-6 rounded shadow hover:shadow-lg transition-all duration-300 ease-in-out ${
              isPending || isSubmitting ? "cursor-progress" : ""
            } ${isPending || isSubmitting ? "opacity-50" : ""}
            }`}
            disabled={isPending | isSubmitting}
          >
            {isPending || isSubmitting ? t("loggingIn") : t("login")}
          </button>
          {error && (
            <div className="mt-3 border-l-2 border-blood bg-red-100 bg-red-900/50 text-xs text-red-900 text-slate-300 p-3 text-left">
              {error.includes("wrong-password")
                ? "Senha incorreta."
                : "E-mail não cadastrado."}
            </div>
          )}
          {errorMsg && (
            <div className="mt-3 border-l-2 border-blood bg-red-100 bg-red-900/50 text-xs text-red-900 text-slate-300 p-3 text-left">
              {errorMsg}
            </div>
          )}
        </form>
        <p className="text-slate-300 text-center mt-10 text-sm">
          Powered by PM ©
        </p>
      </div>
    </div>
  );
}
