import React, { Suspense } from "react";
import { useAuthContext } from "./hooks/useAuthContext";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./pages/login/Login";
import Loading from "./components/Loading";
import AppRoutes from "./AppRoutes";

function App() {
  const { user, authIsReady } = useAuthContext();
  if (!authIsReady) return <Loading />;
  return (
    <div className="App flex text-slate-200 h-[100vh]">
      {user ? (
        <BrowserRouter>
          <Suspense fallback={<Loading />}>
            <AppRoutes />
          </Suspense>
        </BrowserRouter>
      ) : (
        <div className="grow">
          <BrowserRouter>
            <Routes>
              <Route exact path="/" element={<Login />} />
              <Route path="/login" element={<Login />} />
              <Route path="*" element={<Login />} />
            </Routes>
          </BrowserRouter>
        </div>
      )}
    </div>
  );
}

export default App;
