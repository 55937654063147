import React from "react";
import "./Loading.css";

export default function Loading() {
  return (
    <div className="h-20 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
      <img
        className="opacity-pulse w-full h-full"
        src="https://firebasestorage.googleapis.com/v0/b/avaliadordecorpos.appspot.com/o/Design%20sem%20nome.png?alt=media&token=add25f04-7278-41dc-9a62-d1d1271293cf"
        alt=""
      />
    </div>
  );
}
