import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {
  getFirestore,
  initializeFirestore,
  serverTimestamp,
} from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";
// import { getFunctions, connectFunctionsEmulator } from "firebase/functions";

const firebaseConfig = {
  apiKey: "AIzaSyCJ9JWR3CUNGDQ4Mdh2DwLJpND5cEuyF9Q",
  authDomain: "avaliadordecorpos.firebaseapp.com",
  projectId: "avaliadordecorpos",
  storageBucket: "avaliadordecorpos.appspot.com",
  messagingSenderId: "774865129575",
  appId: "1:774865129575:web:c2bc3dbf029210a87ec4c2",
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

// Initialize services
const db = initializeFirestore(firebaseApp, {
  ignoreUndefinedProperties: true,
});
const auth = getAuth(firebaseApp);
const storage = getStorage(firebaseApp);

// Timestamp
const timestamp = serverTimestamp();

export { db, auth, storage, timestamp };
